window.onload = function () {
    this.interval = setInterval(() => {
        let ph = document.getElementById('gigya-textbox-70653334161496020');
        if (ph) {
            clearInterval(this.interval);
            var im = new Inputmask('(999) 999-9999');
            im.mask(ph);
        }
    }, 100);

}
